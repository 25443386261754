import * as React from "react"
import Layout from "../components/general/layout"
import Container from "../components/general/container"
import NewsPageStyle from "../less/news-page.module.less"
import { graphql } from "gatsby"

import useIntl from "../helpers/useIntl"
import TranslateHtml from "../components/general/translateHtml"

const NewsItem = (params) => {
  const intl = useIntl()

  const {
    data,
    pathContext,
  } = params

  const slug = pathContext.slug
  const {node} = data.allNewsItem.edges.find((newsItem) => {
    return newsItem.node.slug === slug
  })

  const newsItem = node;

  const breadcrumbs = []

  let news = {
    ...newsItem,
    label: intl.translate(newsItem, "title"),
    url: "/news/" + newsItem.slug,
  }

  breadcrumbs.push(news)

  return (
    <Layout
      isMarked={true}
      heroFluidImage={newsItem.featuredImg}
      heroTitle={intl.translate(newsItem, "title")}
      showBreadcrumbs={true}
      titleOffset={true}
      breadcrumbs={breadcrumbs}
    >
      <Container size={"small"}>
        <div className={NewsPageStyle.detail}>
          <article className={NewsPageStyle.newsInfo}>
            <TranslateHtml object={newsItem} prop={"body"}/>
          </article>
        </div>
      </Container>
    </Layout>
  )
}

export default NewsItem

export const query = graphql`
  query {
    allNewsItem(sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          body_fr
          body_nl
          featuredImg {
            mobile: childImageSharp {
              fluid(maxWidth: 490) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 1240) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
